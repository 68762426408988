<template>
    <Overview 
        ref="Overview" 
        :moduleName="'Factuur'"
        :moduleNamePlural="'Synchronisatie'"
        :sort="['date_sent', 'identifier']"
        :sortOrder="'-1'"
        :itemService="itemService"
        :itemFormRef="itemFormRef"
        :disableNewButton="true"
        :disableEdit="false"
        :disableDelete="true"
    >
        
        <template #columns>
            <Column header="Klant" field="connection.customer.name" :sortable="true"></Column>
            <Column field="identifier" header="Factuur" :sortable="true"  style="width: 25%"></Column>
            <Column field="mice_status" header="Mice status" :sortable="true"  style="width: 25%"></Column>
            <Column field="total_excl_vat" header="Totaal bedrag" :sortable="true"  style="width: 25%"></Column>
            <Column field="date_sent" header="Factuurdatum" :sortable="true"  style="width: 25%">
                <template #body="slotProps">
                    {{formatDate(slotProps.data.date_sent, 'shortDate')}}
                </template>
            </Column>
            <Column field="debtor.company_name" header="Debiteur" :sortable="true"  style="width: 25%"></Column>
            <Column field="status" header="Status" :sortable="true"  style="width: 10%">
            <template #body="slotProps">
                <p @click="slotProps.data.show = !slotProps.data.show">{{slotProps.data.status}}<template v-if="slotProps.data.last_error">(!)</template></p>
                <template v-if="slotProps.data.last_error && slotProps.data.show">
                    <pre>{{slotProps.data.last_error}}</pre>
                </template>
            </template>
            </Column>
        </template>

        <template #actions>
            <Column :exportable="false" style="width: 8rem">
                <template #body="slotProps">
                    <div class="text-end">
                        <a class="fa-stack" @click="exportInvoice(slotProps.data.connection_id, slotProps.data.connection.destination_connections[0].id, slotProps.data)">
                            <i class="fas fa-circle fa-stack-2x text-warning"></i>
                            <i class="fal fa-file-export fa-stack-1x fa-inverse"></i>
                        </a>
                    </div>
                </template>
            </Column>
            <Column :exportable="false" style="width: 8rem">
                <template #body="slotProps">
                    <a class="fa-stack" data-bs-toggle="modal" data-bs-target="#formModal" @click="editItem(slotProps.data)" v-if="slotProps.data.last_error">
                        <i class="fas fa-circle fa-stack-2x text-danger"></i>
                        <i class="fal fa-bug fa-stack-1x fa-inverse"></i>
                    </a>
                </template>
            </Column>
        </template>
            

        
        <template #form>
            <invoiceFormComponent ref="ItemForm" :itemData="item" />
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from 'primevue/column';
import invoiceFormComponent from '@/components/invoice/InvoiceFormComponent';
import invoiceService from '@/services/InvoiceService';

import store from '@/store/user'

export default {
    components: {
        Overview,
        Column,
        invoiceFormComponent
    },
    data() {
        return {
            item: {},
            itemService: invoiceService,
            itemFormRef: null
        }
    },
    computed: {
        isAdmin () {
            return store.getters.isAdmin
        }
    },
    methods: {
        exportInvoice(connectionId, destinationId, invoice){
            if(invoice.status != 'Geëxporteerd' || confirm('Export is al Geëxporteerd, nogmaals exporteren?')){
                this.loading = true
                invoiceService.exportInvoices(connectionId, destinationId, [invoice.id]).catch(error => {
                    console.log('error', error)
                    this.toastError(error)
                }).finally(() => {
                    this.loading = false  
                    this.$refs.Overview.getIndex()
                })
            }
        },
        editItem(item) {
            this.itemLoading = true;
            this.itemFormRef.reset();

            this.itemService.show(item.id).then(response => {
                this.itemLoading = false;
                this.item = response.data
                this.itemFormRef.setData(response.data);
            }).catch(error => {
                this.toastError(error)
                console.log('error', error) // create notification-toaster for user error
            })
        },
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>

<style scoped>

    a.fa-stack {
        cursor: pointer;
    }

</style>